import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    const fetchLocationData = () => {
      fetch('/api/location')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCountry(decodeURIComponent(data.country));
          setCity(decodeURIComponent(data.city));
          
          const cleanCity = data.city.replace(/[0-9]/g, '');
          const cleanCountry = data.country.replace(/[0-9]/g, '');
  
          const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
          return axios.get(url);
        })
        .then(response => {
          const results = response.data.results;
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
            if (stateObj) {
              setRegion(stateObj.long_name);
            }
          }
          setFetchAttempted(true);
        })
        .catch(error => {
          console.error('Fetch error:', error);
          setCountry('Unavailable');
          setRegion('');
          setCity('');
          setFetchAttempted(true);
        });
    };
  
    if (!region && !fetchAttempted) {
      fetchLocationData();
    }
  }, [region]); 



  const imageUrls = [
    'https://i.ibb.co/D4TXz8B/cropped-20240408-160612904-i-OS.jpg',
    'https://i.ibb.co/dKQtsMK/cropped-20231214-202301867-i-OS.jpg',
    'https://i.ibb.co/1qQcHb6/cropped-20230920-165954639-i-OS.jpg',
    'https://i.ibb.co/vs3HBGX/cropped-20230825-065437833-i-OS.jpg',
    'https://i.ibb.co/r5wLt1B/cropped-20230818-074456263-i-OS.jpg',
    'https://i.ibb.co/HBVRnhY/cropped-20230807-064212619-i-OS.jpg',
    'https://i.ibb.co/C23d2kg/cropped-20230731-183346000-i-OS.jpg'
  ];

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container" >
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

          <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ fontSize: '28px', margin: '0 0 10px 0' }}> 
            <strong>🎁90% OFF SALE🎁 👻</strong>
        </p>
        <div className="detailsText">
            🍑 Kristine <br/>
            ❤️ 24 years<br/>
            🟢 Online Now<br/><br/>

            I personally respond to messages on here   <br/><br/>

            Message me to create a real connection, let’s see where things go ❤️
        </div>
    </div>



      <a href="https://ofmsites.pro/kristineebaby" id="customButton" >
        Send me a message
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
       <strong>Let's have fun 😉 Only $3 today 🔥</strong>
      </p>
      <Analytics/>
    </div>

} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/kristineebaby" element={<G />} />
<Route path="/onlyfans.com/kristineebaby" element={<G />} />
</Routes>
</Router>
  );
}

export default App;
